var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-tabs",
            { attrs: { pills: "" } },
            [
              _c("b-tab", {
                attrs: { title: "댓글 작성 현황" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "comment"
                  },
                },
              }),
              _c("b-tab", {
                attrs: { title: "월별 미션 참여 현황" },
                on: {
                  click: function ($event) {
                    _vm.viewType = "mission-complete"
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _vm.viewType === "comment"
            ? _c("comment-view")
            : _vm.viewType === "mission-complete"
            ? _c("mission-complete-view")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }