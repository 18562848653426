<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab
          title="댓글 작성 현황"
          @click="viewType = 'comment'"
        />
        <b-tab
          title="월별 미션 참여 현황"
          @click="viewType = 'mission-complete'"
        />
      </b-tabs>
    </b-card>

    <b-card>
      <comment-view v-if="viewType === 'comment'" />
      <mission-complete-view v-else-if="viewType === 'mission-complete'" />
    </b-card>
  </div>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import CommentView from './view/CommentView.vue'
import MissionCompleteView from './view/MissionCompleteView.vue'

export default {
  components: {
    BTab,
    BTabs,
    CommentView,
    MissionCompleteView,
  },

  setup() {
    const viewType = ref("comment")

    return {
      viewType,
    }
  },
}
</script>
